<template>

  <div>

    <div class="item__row item__ac">

      <h2>История</h2>

      <v-btn
          small
          class="mx-2"
          fab
          dark
          color="#1f2b45"
          @click="chooseTypeFunction(1)"
      >
        <v-icon dark>
          mdi-plus
        </v-icon>
      </v-btn>

    </div>

    <div class="item__column  pa-4 mb-2 news__list" v-for="item in items" :key="item.id">
      <p class="mb-2">Год : {{ item.year }}</p>

      <p>Описание : {{ item.description }}</p>

      <p>Описание на каз : {{ item.description_kaz }}</p>

      <p>Описание на анг  : {{ item.description_eng }}</p>
      <p>Приоритет : {{ item.priority }}</p>

      <v-divider></v-divider>
      <div class="item__row item__ac">
        <v-btn
            small
            class="mx-2 mr-2"
            fab
            dark
            color="#1f2b45"
            @click="show(item)"
        >
          <v-icon dark>
            mdi-pencil
          </v-icon>
        </v-btn>

        <v-btn
            v-if="me && me.role.role == 'admin'"
            small
            class="mx-2 mr-2"
            fab
            dark
            @click="openDeleteModal(item.id)"
            color="#1f2b45"
        >
          <v-icon dark>
            mdi-trash-can-outline
          </v-icon>
        </v-btn>
      </div>
    </div>
    <v-dialog v-model="destroyModal" width="750">
      <v-card class="pa-6">
        <h3 class="mb-4">Удалить запись</h3>
        <v-btn
            type="submit"
            depressed
            color="primary"
            @click="deleteItem()"
        >
          Да
        </v-btn>

        <v-btn
            depressed
            color="default"
            @click="destroyModal=false"
        >
          Отмена
        </v-btn>
      </v-card>
    </v-dialog>

    <v-dialog v-model="newsModal" width="750">
      <v-card class="pa-6">
        <v-form
            @submit.prevent="callFunction()"
            ref="form"
            class="sign__page__block"
        >

          <h3 class="mb-4" v-if="type==1">Создать </h3>
          <h3 class="mb-4" v-else>Редактировать</h3>
          <div class="item__column">
            <v-text-field
                v-model="title"
                label="Год"
                required
                outlined
                class="input"
                :rules="nameRules"
            ></v-text-field>
          </div>
          <div class="item__column">
            <v-textarea
                v-model="description"
                filled
                name="input-7-4"
                label="Описание"

                :rules="descriptionRules"

            ></v-textarea>
          </div>

          <div class="item__column">
            <v-textarea
                v-model="description_kaz"
                filled
                name="input-7-4"
                label="Описание каз"

                :rules="descriptionRules"

            ></v-textarea>
          </div>

          <div class="item__column">
            <v-textarea
                v-model="description_eng"
                filled
                name="input-7-4"
                label="Описание анг"

                :rules="descriptionRules"

            ></v-textarea>
          </div>

          <div class="item__column">
            <v-text-field
                v-model="priority"
                label="Приоритет"
                required
                outlined
                class="input"
            ></v-text-field>
          </div>



          <v-btn
              type="submit"
              depressed
              color="primary"
              class="mr-2"
          >
            Сохранить изменения
          </v-btn>

          <v-btn
              depressed
              color="default"
              @click="newsModal=false"
          >
            Отмена
          </v-btn>
        </v-form>



      </v-card>
    </v-dialog>

  </div>

</template>

<script>

export default {
  name: "History",
  data() {
    return {
      priority: 0,
      items: [],
      newsModal: false,
      destroyModal: false,
      title: '',
      description: null,
      description_eng: null,
      description_kaz: null,
      nameRules: [
        v => !!v || 'Заполните поле'
      ],
      descriptionRules: [
        v => !!v || 'Заполните поле'
      ],
      files: [],
      type: 0,
      idItem:'',
      me: null,
    };
  },
  methods: {
    getUser() {
      this.$axios({
        method: "get",
        url:
            this.$API_URL +
            this.$API_VERSION +
            "me",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("access_token")}`,
        },
      })
          .then((response) => {
            this.me = response.data;
          })
          .catch((error) => {
            console.warn(error);
          });
    },
    chooseTypeFunction(type) {
      this.type = type;
      this.newsModal = true;
    },
    callFunction() {
      this.type==1?this.create():this.update();
    },
    create() {
      let contractForm = new FormData();
      contractForm.append("year", this.title);
      contractForm.append("description", this.description);
      contractForm.append("description_kaz", this.description_kaz);
      contractForm.append("description_eng", this.description_eng);
      contractForm.append("priority", this.priority);
      this.$axios
          .post(this.$API_URL + this.$API_VERSION + "store/history", contractForm, {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("access_token")}`,
              "Content-Type": "multipart/form-data",
            },
          })
          .then((response) => {
            this.title =null;
            this.description = null;
            this.priority = null;
            console.log(response);
            this.$toast.open({
              message: "Успешно создано",
              type: "success",
              position: "bottom",
              duration: 4000,
              queue: true,
            });

            this.newsModal = false;
            this.type = 0;
            this.$refs.form.reset();
            this.fetch();

          })
          .catch((error) => {
            if (error.response && error.response.status == 422) {
              this.$toast.open({
                message: "Заполните все поля",
                type: "error",
                position: "bottom",
                duration: 4000,
                queue: true,
              });

            }
          });
    },
    update() {
      this.$axios
          .put(this.$API_URL + this.$API_VERSION + "history/show/"+this.idItem,
              {
                year: this.title,
                description: this.description,
                description_kaz: this.description_kaz,
                description_eng: this.description_eng,
                priority: this.priority
              }, {
                headers: {
                  Authorization: `Bearer ${localStorage.getItem("access_token")}`,
                },
              })
          .then((response) => {
            this.title =null;
            this.description = null;
            this.priority = null;
            console.log(response);
            this.$toast.open({
              message: "Успешно обновлено",
              type: "success",
              position: "bottom",
              duration: 4000,
              queue: true,
            });

            this.newsModal = false;

            this.fetch();
          })
          .catch((error) => {
            if (error.response && error.response.status == 422) {
              this.$toast.open({
                message: "Заполните все поля",
                type: "error",
                position: "bottom",
                duration: 4000,
                queue: true,
              });

            }
          });
    },
    fetch() {
      this.$axios({
        method: "get",
        url:
            this.$API_URL +
            this.$API_VERSION +
            "history",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("access_token")}`,
        },
      })
          .then((response) => {
            this.items = response.data;
            console.log(this.items);
          })
          .catch((error) => {
            console.log(error);
          });
    },
    openDeleteModal(item) {
      this.destroyModal = true;
      this.idItem = item;
    },
    show(item) {
        console.log(item);
        this.idItem = item.id;
        this.description = item.description;
        this.description_kaz = item.description_kaz;
        this.description_eng = item.description_eng;
        this.title = item.year;
        this.priority = item.priority;
        this.newsModal = true;
    },
    deleteItem() {
      this.$axios({
        method: "delete",
        url:
            this.$API_URL +
            this.$API_VERSION +
            "delete/history/"+this.idItem,
        headers: {
          Authorization: `Bearer ${localStorage.getItem("access_token")}`,
        },
      })
          .then((response) => {
            this.title = response.data.title;
            this.description = response.data.description;
            this.fetch();
            this.destroyModal = false
          })
          .catch((error) => {
            console.log(error);
          });
    },
  },
  mounted() {
    this.fetch();
    this.getUser();
  },
  beforeMount() {

  },
  watch: {
    newsModal(val) {
      if(val==false) {
        this.idItem = '';
        this.description = '';
        this.description_kaz = '';
        this.description_eng ='';
        this.title = '';
        this.$refs.form.reset();
      }
    },
  },
};
</script>


<style lang="scss">
.news__list {
  img {
    width: 100px;
    height: 100px;
    object-fit: cover;
  }
}
</style>
